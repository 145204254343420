import React from 'react'
import tw from 'twin.macro'

import { SubheadSMStyle } from './UI'

import { GatsbyImage } from 'gatsby-plugin-image'

const Featured = ({ data }) => {
  return (
    <section tw="my-14">
      <div tw="mx-7 lg:(max-w-screen-lg mx-auto)">
        <div tw="lg:(flex items-center)">
          <div
            css={[
              SubheadSMStyle,
              tw`w-full text-center mb-8 text-[#646b75] lg:(w-1/6 mb-0)`,
            ]}
          >
            Featured in
          </div>
          <div tw="w-full flex justify-between">
            {data &&
              data.map((featured, index) => (
                <GatsbyImage
                  key={`featured-${index}`}
                  image={featured.logo.gatsbyImageData}
                  tw="w-1/6 px-2 lg:(px-8 max-h-10)"
                  objectFit="contain"
                  alt=""
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Featured
